import React from "react";
import { Route, Redirect } from "react-router-dom";
// import { authenticationService } from "services/Authentication.service.js";

export const PrivateRoute = ({
  component: Component,
  roles,
  tables,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      // const currentStudent = authenticationService.currentStudentValue;

      // if (!currentStudent) {
      //   return (
      //     <Redirect
      //       to={{ pathname: "/login", state: { from: props.location } }}
      //     />
      //   );
      // } else {
      return <Component {...props} tables={tables} />;
      // }
    }}
  />
);
