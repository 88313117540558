/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Table,
  CustomInput,
} from "reactstrap";
import axios from "axios";
import * as dateFns from "date-fns";
import Select from "react-select";
import Cards from "react-credit-cards-2";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatFormData,
} from "../helpers/Utils";
import { date } from "yup";

class Referral extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();
  }

  getMainState = () => {
    return {
      FirstName: "",
      LastName: "",
      Email: "",
      Phone: "",
      Language: "",
      PartnerCode: "",
      ReferralList: [],
      IsSubmitting: false,
      TermConditionsAccepted: false,
      ModalTermsIsOpen: false,
    };
  };

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let modalState = this.state;
    modalState[name] = value;

    this.setState({ modalState: modalState });
  };

  handleLanguageChange = (selectedOption) => {
    let mainState = this.state;

    mainState.Language = selectedOption.value;

    this.setState({ mainState: mainState });
  };

  onCheckAcceptTerms = (e) => {
    let modalState = this.state;

    modalState.TermConditionsAccepted = e.target.checked;

    this.setState(modalState);
  };

  toggleAcceptTermsModal = (e) => {
    let modalState = this.state;

    modalState.ModalTermsIsOpen = !modalState.ModalTermsIsOpen;

    this.setState(modalState);
  };

  render() {
    let referralList = this.state.ReferralList.map((referral, index) => {
      return (
        <tr key={index}>
          <td>{referral.firstName}</td>
          <td>{referral.lastName}</td>
          <td className="hideColumn">{referral.email}</td>
          <td className="hideColumn">{referral.phone}</td>
          <td>{referral.language}</td>
          <td>{referral.status}</td>
          <td className="hideColumn">
            {dateFns.format(referral.createdAt, "MM/dd/yyyy")}
          </td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">
                    Please provide your friend's information for the referral
                  </CardTitle>
                </CardHeader>

                <CardBody>
                  <Row form>
                    <Col md={2}>
                      <FormGroup>
                        <Label for="address">First Name</Label>
                        <Input
                          className={`form-group`}
                          type="text"
                          name="FirstName"
                          id="addrFirstNameessCity"
                          autoComplete="nope"
                          value={this.state.FirstName}
                          onChange={this.handleUserInput.bind(this)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label for="address">Last Name</Label>
                        <Input
                          className={`form-group`}
                          type="text"
                          name="LastName"
                          id="LastName"
                          autoComplete="nope"
                          value={this.state.LastName}
                          onChange={this.handleUserInput.bind(this)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label for="address">E-mail</Label>
                        <Input
                          className={`form-control ${this.errorClass()}`}
                          type="text"
                          name="Email"
                          id="Email"
                          autoComplete="nope"
                          value={this.state.Email}
                          onChange={this.handleUserInput.bind(this)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label for="address">Phone</Label>
                        <Input
                          className={`form-group`}
                          type="text"
                          name="Phone"
                          id="Phone"
                          autoComplete="nope"
                          value={this.state.Phone}
                          onChange={this.handleUserInput.bind(this)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Language</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.Language &&
                            this.languageOptions.find(
                              (x) => x.value === this.state.Language
                            )
                          }
                          onChange={this.handleLanguageChange}
                          options={this.languageOptions}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label>&nbsp;</Label>
                        <Button
                          color="primary"
                          style={{ margin: "0" }}
                          onClick={this.save.bind(this)}
                          disabled={!this.validateForm()}
                        >
                          save
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <FormGroup style={{ marginTop: "1rem" }}>
                        <label for="txt1">
                          <input
                            type="checkbox"
                            name="link1"
                            value="link"
                            id="txt1"
                            style={{ marginRight: "5px" }}
                            onClick={this.onCheckAcceptTerms.bind(this)}
                          />
                          {"I accept the"}
                          <a
                            href=""
                            target=""
                            style={{ marginLeft: "5px" }}
                            onClick={(event) => {
                              event.preventDefault();
                              this.toggleAcceptTermsModal();
                            }}
                          >
                            {"Transfer Program Rules and Regulations"}
                          </a>
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Previous referrals</CardTitle>
                </CardHeader>

                <CardBody>
                  <Row form>
                    <Table className="table table-striped">
                      <thead>
                        <tr>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th className="hideColumn">E-mail</th>
                          <th className="hideColumn">Phone</th>
                          <th>Language</th>
                          <th>Status</th>
                          <th className="hideColumn">Created At</th>
                        </tr>
                      </thead>
                      <tbody>{referralList}</tbody>
                    </Table>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={this.state.ModalTermsIsOpen}
            centered={true}
            autoFocus={true}
            size={"lg"}
            backdrop="static"
            scrollable
          >
            <ModalHeader>Transfer Program Rules and Regulations</ModalHeader>
            <ModalBody>
              <Row form>
                <Col md={12}>
                  <FormGroup>
                    <p style={{ fontWeight: "bold", textAlign: "justify" }}>
                      1. Referral Commission{" "}
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      Students who refer a friend to transfer to Lumos Language
                      School for the Fall 2024 Semester will receive a
                      commission of $300, and the referred friend who
                      transferred in will receive a discount of $300 towards
                      their tuition.
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      The commission will be paid out once the referred student
                      enrolls and completes the registration process, paying at
                      least 50% of their tuition.
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      Students must refer their friends through the referral
                      page in the student portal.
                    </p>

                    <p style={{ fontWeight: "bold", textAlign: "justify" }}>
                      2. Commission Payment{" "}
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      If a student has an open balance with the school, their
                      commission will be paid out as a credit towards their
                      outstanding balance.
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      Students must ensure their account is in good standing to
                      receive the commission in cash/check.
                    </p>

                    <p style={{ fontWeight: "bold", textAlign: "justify" }}>
                      3. Commission Payout{" "}
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      The commission will be paid out either as a check or
                      credit towards the student's account.
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      To receive the commission by check, the student must
                      provide their social security number for tax purposes.
                      Check with HR for more information.
                    </p>

                    <p style={{ fontWeight: "bold", textAlign: "justify" }}>
                      4. Eligibility{" "}
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      To be eligible for the referral commission, the referring
                      student must be currently enrolled at Lumos Language
                      School or on break.
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      The referred student must be a new student who has not
                      previously enrolled at Lumos Language School.
                    </p>

                    <p style={{ fontWeight: "bold", textAlign: "justify" }}>
                      5. Verification Process{" "}
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      Both the referring student and the referred student will
                      be required to complete a verification process to confirm
                      the referral.
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      Any fraudulent or dishonest referrals will result in
                      disqualification from the referral program.
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      The referred student will receive updates periodically on
                      how the transfer process is for their referral from Lumos’
                      sales department.
                    </p>

                    <p style={{ fontWeight: "bold", textAlign: "justify" }}>
                      6. Program Changes
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      The school reserves the right to modify or terminate the
                      referral program at any time without prior notice.
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      Any changes to the program will be communicated to
                      students through email or other official communication
                      channels.
                    </p>

                    <p style={{ fontWeight: "bold", textAlign: "justify" }}>
                      7. Compliance
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      All students participating in the referral program must
                      comply with the school's code of conduct and policies.
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      Failure to comply may result in disqualification from the
                      program and other disciplinary actions.
                    </p>

                    <p style={{ fontWeight: "bold" }}>8. Governing Law</p>

                    <p style={{ textAlign: "justify" }}>
                      These rules and regulations are governed by the laws of
                      Utah, USA.
                    </p>

                    <p style={{ fontWeight: "bold" }}>
                      8. Contact Information{" "}
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      For any questions or concerns regarding the referral
                      program, please contact the front desk.
                    </p>

                    <p style={{ fontWeight: "bold" }}>9. Effective Date </p>

                    <p style={{ textAlign: "justify" }}>
                      These rules and regulations are effective as of June 16,
                      2024.
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      The ending date is September 30, 2024.
                    </p>

                    <p style={{ fontWeight: "bold" }}>10. Agreement </p>

                    <p style={{ textAlign: "justify" }}>
                      By participating in the referral program, students agree
                      to abide by these rules and regulations.
                    </p>

                    <p style={{ fontWeight: "bold" }}>11. Disclaimer </p>

                    <p style={{ textAlign: "justify" }}>
                      The school reserves the right to modify these rules and
                      regulations at any time without prior notice.
                    </p>
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={this.toggleAcceptTermsModal.bind(this)}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </>
    );
  }

  async componentDidMount() {
    this._refreshReferrals();
  }

  _refreshReferrals = async () => {
    await axios.get("students/get-all-campaign").then((response) => {
      let referralList = [];

      response.data.data.map((referral, index) =>
        referralList.push({
          id: referral.id,
          firstName: referral.firstName,
          lastName: referral.lastName,
          phone: referral.phone,
          email: referral.email,
          partnerCode: referral.partnerCode,
          status:
            referral.status == "I"
              ? "In Progress"
              : referral.status == "A"
              ? "Applied"
              : referral.status == "C"
              ? "Completed"
              : referral.status == "Q"
              ? "Not Qualify"
              : referral.status == "D"
              ? "Canceled"
              : "",
          language:
            referral.language == "EN"
              ? "English"
              : referral.language == "PT"
              ? "Portuguese"
              : "Spanish",
          createdAt: dateFns.parseISO(referral.createdAt),
        })
      );

      this.setState({ ReferralList: referralList });
    });
  };

  isSubmitting(bool) {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          IsSubmitting: bool,
        },
        resolve(true)
      );
    });
  }

  save() {
    const self = this;

    if (this.state.IsSubmitting === false) {
      this.isSubmitting(true).then(async () => {
        let obj = {
          FirstName: self.state.FirstName,
          LastName: self.state.LastName,
          Email: self.state.Email,
          Phone: self.state.Phone,
          Language: self.state.Language,
        };

        axios
          .post("students/add-campaign", obj)
          .then((response) => {
            self.isSubmitting(false).then(() => {
              if (response.data.success === true) {
                self.resetState().then(() => {
                  self._refreshReferrals();
                });
              } else {
                // let message = response.data.messages
                //   ? response.data.messages.map((error) => error).join("\n")
                //   : "An internal server error has occurred.";
                // self.setSubmitError(message);
              }
            });
          })
          .catch(function (error) {
            self.isSubmitting(false).then(() => {
              // let message =
              //   "A server connection error occurred in your application. Error message: " +
              //   self.ErrorMessage(error);
              // self.setSubmitError(message);
            });
          });
      });
    }
  }

  validateForm() {
    return (
      this.state.FirstName.length > 0 &&
      this.state.LastName.length > 0 &&
      this.state.Email.length > 0 &&
      this.state.Email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) &&
      this.state.Phone.length > 0 &&
      this.state.Language.length > 0 &&
      this.state.TermConditionsAccepted == true
    );
  }

  errorClass() {
    return this.state.Email.length === 0 ||
      this.state.Email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      ? ""
      : "is-invalid";
  }

  resetState = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          FirstName: "",
          LastName: "",
          Email: "",
          Phone: "",
          Language: "",
        },
        resolve(true)
      );
    });
  };

  languageOptions = [
    { value: "EN", label: "English" },
    { value: "PT", label: "Portuguese" },
    { value: "SP", label: "Spanish" },
  ];
}

export default Referral;
