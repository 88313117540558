import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.jsx";
import LoginPage from "views/LoginPage.jsx";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.1.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "assets/css/App.css";
import "assets/css/flags.css";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "helpers/Loader.js";
import axios from "axios";
import { authHeader } from "helpers/AuthHeader.js";
import { ErrorMessage } from "helpers/Utils.js";
import { colors } from "variables/general.jsx";
import { history } from "helpers/History.js";
// import { authenticationService } from "services/Authentication.service.js";
import {
  messageService,
  swalmessageService,
} from "services/Message.service.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga";
import swal from "sweetalert";
import { getToken, getCachedUser } from "../services/adalConfig";

ReactGA.initialize("UA-77505655-4");
history.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search);
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      refCount: 0,
      showingError: false,
      authToken: "",
    };
  }

  notificationAlert = React.createRef();

  notify(message, color) {
    toast.success(message);
  }

  setLoading(isLoading) {
    const self = this;

    if (isLoading) {
      self.setState({
        refCount: self.state.refCount + 1,
        isLoading: true,
      });
    } else if (self.state.refCount > 0) {
      let count = self.state.refCount - 1;

      self.setState({
        refCount: count,
        isLoading: count > 0,
      });
    }
  }

  componentDidMount() {
    toast.configure({
      position: "top-center",
    });

    this.subscription = messageService.getMessage().subscribe((message) => {
      if (message) {
        this.notify(message.text, colors.SUCCESS);
      }
    });

    this.swalSubscription = swalmessageService
      .getMessage()
      .subscribe((message) => {
        if (message) {
          swal(message);
        }
      });
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
    this.swalSubscription.unsubscribe();
  }

  componentWillMount() {
    const self = this;

    axios.interceptors.request.use(
      function (config) {
        let route = config.url.replace(config.baseURL, "");

        if (
          route !== "dashboard" &&
          route !== "dashboard/tables" &&
          !route.toUpperCase().includes("APPLICATION") &&
          !route.toUpperCase().includes("APPLY") &&
          !route.toUpperCase().includes("BASIC") &&
          !route.toUpperCase().includes("SELFENROLL")
        ) {
          self.setLoading(true);
        }

        const isDevelopment = false;
        const url = isDevelopment
          ? "http://localhost:58794"
          : "https://apistudent.lumos.edu/";

        //:  "http://back.sun.psisoftwarehouse.com/";

        config.baseURL = url;
        let obj = authHeader();
        obj.AuthToken = self.state.authToken;

        config.headers = obj;

        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        setTimeout(() => {
          self.setLoading(false);
        }, 100);

        if (response.data && response.data.messages) {
          let message = response.data.messages
            .map((message) => message)
            .join("\n");

          if (message) {
            if (!response.data.showModalSuccess) toast.success(message);
            else swal("Success", message, "success");
          }
        }

        return response;
      },
      function (error) {
        if (error && error.response) {
          if ([401, 403].indexOf(error.response.status) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            //Verificar se fará logout ou apenas exibirá a msg <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
            // authenticationService.logout();
            // history.push("/login");
          }

          // const error = (data && data.message) || response.statusText;
          // return Promise.reject(error);
        }

        self.setLoading(false);

        if (!self.state.showingError) {
          self.setState({ showingError: true });

          let title = "Error";
          var message = ErrorMessage(error);

          if (!message.includes("401")) {
            swal(title, message, "error");

            // title = "Expired password";
            // message = "Your password has expired. Please, login again.";
          }

          setTimeout(() => {
            self.setState({ showingError: false });
          }, 100);
        }

        return Promise.reject(error);
      }
    );
  }

  render() {
    return (
      <Router history={history}>
        <Loader isVisible={this.state.isLoading} />
        <Switch>
          {/* <Route path="/login" render={(props) => <LoginPage {...props} />} /> */}
          <Route path="/admin" component={AdminLayout} />
          <Redirect to="/admin/dashboard" />
        </Switch>
      </Router>
    );
  }
}

export { App };
