/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Button,
  Collapse,
} from "reactstrap";
import axios from "axios";
import myData from "../Countries.json";
import { FormatMonthYearDateList, editMode } from "helpers/Utils.js";
import { SavePdf } from "components/Report/GeneratePdf.js";
import * as dateFns from "date-fns";
import { Doughnut, Bar } from "react-chartjs-2";
import { colorArray } from "colors.js";
import ModalMessage from "components/Message/Message.Component";
import AssignmentView from "components/Assignment/Assignment.component.view";
import confirm from "reactstrap-confirm";

const campusColor = ["#6ad0b3", "#fd7c6e", "#fbc657"];

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();
  }

  getMainState = () => {
    return {
      Student: {
        Subjects: [],
        Messages: [],
        Invoices: [],
      },
      AttendanceChartList: [],
      SyllabusList: [],
      Message: {},
      ModalMessageIsOpen: false,
      SelectClassIdForAssignmentModal: "",
      AssignmentModalIsOpen: false,
    };
  };

  render() {
    let subjects = this.state.Student.Subjects.map((sub, index) => {
      let average = (sub.Grade / sub.MaxGrade) * 100;

      return (
        <Col
          style={{ cursor: "pointer" }}
          lg="3"
          md="6"
          sm="6"
          onClick={this.setClassIdAndOpenModal.bind(this, sub.Id)}
        >
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="9" xs="9">
                  <div className="numbers">
                    <p className="card-category">{sub.Code}</p>
                    <p />
                  </div>
                </Col>
                <Col md="3" xs="3">
                  <div className="icon-big text-center icon-warning"></div>
                </Col>
              </Row>
              <Row>
                <Col md="6" xs="6" style={{ paddingRight: "2px" }}>
                  <div className="numbers" style={{ textAlign: "right" }}>
                    <p
                      className="card-category"
                      style={{
                        fontSize: "1.8rem",
                        color: average < 70 ? "#fd7c6e" : "#6ad0b3",
                      }}
                    >
                      {sub.Grade.toFixed(0)}
                    </p>
                  </div>
                </Col>
                <Col md="6" xs="6" style={{ paddingLeft: "2px" }}>
                  <div className="numbers" style={{ textAlign: "left" }}>
                    <p
                      className="card-category"
                      style={{ fontSize: "small", lineHeight: "45px" }}
                    >
                      {"(Out of " + sub.MaxGrade + ")"}
                    </p>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6" xs="6" style={{ paddingRight: "2px" }}>
                  <div className="numbers" style={{ textAlign: "right" }}>
                    <p
                      className="card-category"
                      style={{
                        fontSize: "1.8rem",
                        color: average < 70 ? "#fd7c6e" : "#6ad0b3",
                      }}
                    >
                      {average.toFixed(2) + "%"}
                    </p>
                  </div>
                </Col>
                <Col md="6" xs="6" style={{ paddingLeft: "2px" }}>
                  <div className="numbers" style={{ textAlign: "left" }}>
                    <p
                      className="card-category"
                      style={{ fontSize: "small", lineHeight: "45px" }}
                    >
                      {"(Current grade " + sub.GradeLetter + ")"}
                    </p>
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">{sub.TeacherName}</div>
            </CardFooter>
          </Card>
        </Col>
      );
    });

    let options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Chart.js Bar Chart",
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              min: 0,
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              min: 0,
            },
          },
        ],
      },
    };

    const labels = [""];

    // let data = {
    //   labels,
    //   datasets: [
    //     {
    //       label: "Your Grade (45)",
    //       data: labels.map(() => 45),
    //       backgroundColor: "#fd7c6e",
    //     },
    //     {
    //       label: "Max Subject Grade (100)",
    //       data: labels.map(() => 100),
    //       backgroundColor: "#6ad0b3",
    //     },
    //   ],
    // };

    let subjects2 = this.state.Student.Subjects.map((sub, index) => {
      let average = (sub.Grade / sub.MaxGrade) * 100;

      let gradeData = {
        labels,
        datasets: [
          {
            label: "Your Grade (" + sub.Grade + ")",
            data: labels.map(() => sub.Grade),
            backgroundColor: "#fd7c6e",
          },
          {
            label: "Max Subject Grade (" + sub.MaxGrade + ")",
            data: labels.map(() => sub.MaxGrade),
            backgroundColor: "#6ad0b3",
          },
        ],
      };

      return (
        <Col
          style={{ cursor: "pointer" }}
          lg="3"
          md="6"
          sm="6"
          onClick={this.setClassIdAndOpenModal.bind(this, sub.Id)}
        >
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="9" xs="9">
                  <div className="numbers">
                    <p className="card-category">
                      {sub.Code +
                        (sub.GradeLetter && " - Grade " + sub.GradeLetter)}
                    </p>
                    <p />
                  </div>
                </Col>
                <Col md="3" xs="3">
                  <div className="icon-big text-center icon-warning"></div>
                </Col>
              </Row>
              <Row>
                <Bar options={options} data={gradeData} />
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">{sub.TeacherName}</div>
            </CardFooter>
          </Card>
        </Col>
      );
    });

    let messages = this.state.Student.Messages.map((message) => {
      return (
        <tr
          key={message.Id}
          onClick={this.setMessageAndOpenModal.bind(this, message.Id)}
          style={{ cursor: "pointer" }}
        >
          <td>{message.Title}</td>
          <td>{dateFns.format(message.CreatedDate, "MM/dd/yyyy hh:mm")}</td>
          <td>{message.UserName}</td>
        </tr>
      );
    });

    let syllabusList = this.state.SyllabusList.map((syllabus) => {
      return (
        <tr key={syllabus.Id}>
          <td>{syllabus.Name}</td>
          <td>
            <Button
              color="info"
              size="sm"
              className="mr-2"
              onClick={this.downloadSyllabus.bind(this, syllabus.Id)}
            >
              {"Download"}
            </Button>
          </td>
        </tr>
      );
    });

    let invoices = this.state.Student.Invoices.map((invoice) => {
      return (
        <tr
          key={invoice.Id}
          style={{
            backgroundColor: invoice.DueDate < new Date() ? "#ffe9e9" : "",
          }}
        >
          <td>{invoice.DocNumber}</td>
          <td>{"$" + invoice.Balance}</td>
          <td>{dateFns.format(invoice.DueDate, "MM/dd/yyyy")}</td>
          <td>
            <Button
              color="success"
              size="sm"
              className="mr-2"
              onClick={this.raiseInvoiceClicked.bind(this, invoice.InvoiceLink)}
            >
              Pay
            </Button>
          </td>
        </tr>
      );
    });

    // let options = {
    //   plugins: {
    //     title: {
    //       display: true,
    //       text: "Chart.js Bar Chart - Stacked",
    //     },
    //   },
    //   responsive: true,
    //   interaction: {
    //     mode: "index",
    //     intersect: false,
    //   },
    //   scales: {
    //     x: {
    //       stacked: true,
    //     },
    //     y: {
    //       stacked: true,
    //     },
    //   },
    // };

    // let subjectsChart = this.state.Student.Subjects.map((sub, index) => {
    //   return (
    //     <Col
    //       style={{ cursor: "pointer" }}
    //       lg="3"
    //       md="6"
    //       sm="6"
    //       onClick={this.setClassIdAndOpenModal.bind(this, sub.Id)}
    //     >
    //       <Card className="card-stats">
    //         <CardBody>
    //           <Row>
    //             <Bar options={options} data={data} />
    //           </Row>
    //         </CardBody>
    //         <CardFooter>
    //           <hr />
    //           <div className="stats">{sub.TeacherName}</div>
    //         </CardFooter>
    //       </Card>
    //     </Col>
    //   );
    // });

    return (
      <>
        <div className="content">
          <Row>
            {this.state.SyllabusList && this.state.SyllabusList.length > 0 && (
              <Col md="6">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h5">Syllabus</CardTitle>
                  </CardHeader>

                  <CardBody>
                    <Table>
                      <thead className="text-primary">
                        <tr>
                          <th>File Name</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>{syllabusList}</tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            )}

            {this.state.Student.Messages &&
              this.state.Student.Messages.length > 0 && (
                <Col md="6">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h5">Unread Messages</CardTitle>
                    </CardHeader>

                    <CardBody>
                      <Table>
                        <thead className="text-primary">
                          <tr>
                            <th>Title</th>
                            <th>Date</th>
                            <th>User</th>
                          </tr>
                        </thead>
                        <tbody>{messages}</tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              )}

            {this.state.Student.Invoices &&
              this.state.Student.Invoices.length > 0 && (
                <Col md="6">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h5">Unpaid Invoices</CardTitle>
                    </CardHeader>

                    <CardBody>
                      <Table>
                        <thead className="text-primary">
                          <tr>
                            <th>Doc #</th>
                            <th>Balance</th>
                            <th>DueDate</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>{invoices}</tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              )}

            {/* {subjects} */}
            {subjects2}
            {/* {subjectsChart} */}

            {this.state.AttendanceChartList &&
              this.state.AttendanceChartList.length > 0 && (
                <Col md="3">
                  <Card className="card-stats">
                    {/* <CardHeader>
                      <CardTitle tag="h5">Attendance</CardTitle>
                    </CardHeader> */}
                    <CardBody>
                      <Row>
                        <Col md="9" xs="9">
                          <div className="numbers">
                            <p className="card-category">Attendance</p>
                            <p />
                          </div>
                        </Col>
                        <Col md="3" xs="3">
                          <div className="icon-big text-center icon-warning"></div>
                        </Col>
                      </Row>

                      <Row>{this.renderDoughnutCampus()}</Row>
                    </CardBody>
                    <CardFooter>
                      <hr />
                      <div className="stats">
                        <i className="fa fa-sync-alt" /> Updated now
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              )}
          </Row>
        </div>

        <ModalMessage
          isOpen={this.state.ModalMessageIsOpen}
          toggle={this.toggleModalMessage.bind(this)}
          description={this.state.Message && this.state.Message.Description}
          title={this.state.Message && this.state.Message.Title}
          messageId={this.state.Message && this.state.Message.Id}
        />

        <AssignmentView
          classId={this.state.SelectClassIdForAssignmentModal}
          isOpen={this.state.AssignmentModalIsOpen}
          toggleAssignmentModal={this.toggleAssignmentModal.bind(this)}
        />
      </>
    );
  }

  chartJsData(resultSet) {
    return {
      datasets: [
        {
          data: resultSet.map(function (r) {
            return r.Total;
          }),
          backgroundColor: resultSet.map(function (r, i) {
            if (r.Name.includes("Present")) return campusColor[0];
            if (r.Name.includes("Absent")) return campusColor[1];
            if (r.Name.includes("Tardy")) return campusColor[2];
          }),
        },
      ],

      labels: resultSet.map(function (c) {
        return c.Name + " (" + c.Total + ")";
      }),
    };
  }

  chartJsDataWithTotal(resultSet) {
    return {
      datasets: [
        {
          data: resultSet.map(function (r) {
            return r.Total;
          }),
          backgroundColor: resultSet.map(function (r, i) {
            return colorArray[i].hex;
          }),
        },
      ],

      labels: resultSet.map(function (c) {
        return c.Name + ":  " + c.Total;
      }),
    };
  }

  legendOptions(show) {
    return {
      display: show,
      position: "right",
    };
  }

  renderDoughnutCampus = () => {
    return (
      <Doughnut
        data={this.chartJsData(this.state.AttendanceChartList)}
        width={200}
        height={200}
        options={{ maintainAspectRatio: false }}
        legend={{
          display: true,
          position: "top",
          align: "center",
        }}
      />
    );
  };

  _refreshStudent = () => {
    return new Promise((resolve, reject) => {
      axios.get("students/byId").then((response) => {
        let mainState = this.state;

        mainState.Student.Subjects = [];
        response.data.data.studentClasses.map((studentClass, index) =>
          mainState.Student.Subjects.push({
            Id: studentClass.classe.id,
            Code: studentClass.classe.code,
            Grade: studentClass.marks ? studentClass.marks : 0,
            MaxGrade: studentClass.classe.marks ? studentClass.classe.marks : 0,
            TeacherName: studentClass.classe.teacher.name,
            GradeLetter: studentClass.gradeLetter ?? "",
          })
        );

        this.setState(mainState, resolve(true));
      });
    });
  };

  _refreshGetAdditionalBasicData = () => {
    return new Promise((resolve, reject) => {
      axios.get("students/GetAdditionalBasicData").then((response) => {
        let mainState = this.state;

        mainState.Student.Messages = [];
        response.data.data.messageList.map((message, index) =>
          mainState.Student.Messages.push({
            Id: message.id,
            Title: message.title,
            CreatedDate: dateFns.parseISO(message.createdDate),
            UserName: message.user.name.replace("@lumos.edu", ""),
            Description: message.description.includes("<!DOCTYPE html>")
              ? message.description
              : message.description.split("\n").map((item, key) => {
                  return (
                    <span key={key}>
                      {item}
                      <br />
                    </span>
                  );
                }),
          })
        );

        mainState.Student.Invoices = [];
        response.data.data.invoiceDetailList.map((invoice, index) =>
          mainState.Student.Invoices.push({
            Id: invoice.id,
            Balance: invoice.balance,
            DueDate: dateFns.parseISO(invoice.dueDate),
            DocNumber: invoice.docNumber,
            InvoiceLink: invoice.invoiceLink,
          })
        );

        this.setState(mainState, resolve(true));
      });
    });
  };

  _refreshDashboard = () => {
    return new Promise((resolve, reject) => {
      axios.get("dashboard").then((response) => {
        let mainState = this.state;

        mainState.AttendanceChartList = [];
        response.data.data.attendanceChartList.map((attendance, index) =>
          mainState.AttendanceChartList.push({
            Name: attendance.status
              .replace("A", "Absent")
              .replace("P", "Present")
              .replace("T", "Tardy"),
            Total: attendance.total,
          })
        );

        mainState.SyllabusList = [];
        response.data.data.syllabusList.map((syllabus, index) =>
          mainState.SyllabusList.push({
            Id: syllabus.id,
            Name: syllabus.name,
          })
        );

        this.setState(mainState, resolve(true));
      });
    });
  };

  async componentDidMount() {
    this._refreshStudent().then(() => {
      this._refreshGetAdditionalBasicData().then(() => {
        this._refreshDashboard();
      });
    });

    // this.timer = setInterval(() => {
    //   this._refreshDashboard();
    // }, 300000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  raiseInvoiceClicked = async (url) => {
    await confirm({
      title: <>Warning</>,
      message:
        "Please be aware that payment can take up to 30 minutes to be considered on the portal. Click OK to proceed.",
      cancelText: null,
    });

    window.open(url, "_blank");
  };

  setMessageAndOpenModal = (id) => {
    let mainState = this.state;
    let message = this.state.Student.Messages.find((x) => x.Id == id);

    mainState.Message = message;

    this.setState(mainState, () => {
      this.toggleModalMessage();
    });
  };

  toggleModalMessage = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.ModalMessageIsOpen = !mainState.ModalMessageIsOpen;
      this.setState(mainState, resolve(true));
    });
  };

  toggleAssignmentModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;

      mainState.AssignmentModalIsOpen = !mainState.AssignmentModalIsOpen;

      this.setState(mainState, resolve(true));
    });
  };

  setClassIdAndOpenModal = (id) => {
    let mainState = this.state;

    mainState.SelectClassIdForAssignmentModal = id;

    this.setState(mainState, () => {
      this.toggleAssignmentModal();
    });
  };

  downloadSyllabus(id) {
    axios
      .get("documents/getFile?id=" + id, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.click();
      });
  }
}

export default Dashboard;
