/* eslint-disable array-callback-return */
import React, { Fragment } from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Switch } from "react-router-dom";
import DemoNavbar from "components/Navbars/DemoNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import routes from "routes.js";
import { PrivateRoute } from "components/PrivateRoute/PrivateRoute.jsx";
import axios from "axios";

var ps;

class AdminLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      tables: {},
      finished: false,
    };
    this.mainPanel = React.createRef();
  }

  componentDidMount() {
    this.getTables().then(() => {
      this.setState({ finished: true });
    });

    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }

  async getTables() {
    await axios.get("dashboard/tables").then((response) => {
      let mainState = this.state;

      mainState.tables.classrooms = [];
      response.data.data.tables.classroomList.map((classroom, index) =>
        mainState.tables.classrooms.push({
          id: classroom.id,
          name: classroom.name,
          campusName: classroom.campus && classroom.campus.name,
        })
      );

      mainState.tables.campuses = [];
      response.data.data.tables.campusList.map((campus, index) =>
        mainState.tables.campuses.push({
          id: campus.id,
          name: campus.name,
        })
      );

      mainState.tables.courses = [];
      response.data.data.tables.courseList.map((course, index) =>
        mainState.tables.courses.push({
          id: course.id,
          name: course.name,
          minSize: course.minSize,
          maxSize: course.maxSize,
          order: course.order,
          tuitionCost: course.tuitionCost,
        })
      );

      mainState.tables.semesters = [];
      response.data.data.tables.semesterList.map((semester, index) =>
        mainState.tables.semesters.push({
          id: semester.id,
          name: semester.name,
          current: semester.current,
          startDate: semester.startDate,
          endDate: semester.endDate,
        })
      );

      mainState.tables.roles = [];
      response.data.data.tables.roleList.map((role, index) =>
        mainState.tables.roles.push({
          id: role.id,
          name: role.name,
        })
      );

      mainState.tables.assignmentTypes = [];
      response.data.data.tables.assignmentTypeList.map(
        (assignmentType, index) =>
          mainState.tables.assignmentTypes.push({
            id: assignmentType.id,
            name: assignmentType.name,
          })
      );

      mainState.tables.statusTypes = [];
      response.data.data.tables.statusTypeList.map((statusType, index) =>
        mainState.tables.statusTypes.push({
          id: statusType.id,
          name: statusType.name,
          allowClassEnroll: statusType.allowClassEnroll,
          hasDate: statusType.hasDate,
          enrolled: statusType.enrolled,
          unenrolled: statusType.unenrolled,
        })
      );

      mainState.tables.documentTypes = [];
      response.data.data.tables.documentTypeList.map((documentType, index) =>
        mainState.tables.documentTypes.push({
          id: documentType.id,
          name: documentType.name,
        })
      );

      mainState.tables.visaTypes = [];
      response.data.data.tables.visaTypeList.map((visaType, index) =>
        mainState.tables.visaTypes.push({
          id: visaType.id,
          name: visaType.name,
        })
      );

      mainState.tables.studentOrigins = [];
      response.data.data.tables.studentOriginList.map((studentOrigin, index) =>
        mainState.tables.studentOrigins.push({
          id: studentOrigin.id,
          name: studentOrigin.name,
        })
      );

      mainState.tables.holidays = [];
      response.data.data.tables.holidayList.map((holiday, index) =>
        mainState.tables.holidays.push({
          id: holiday.id,
          name: holiday.name,
          date: holiday.date,
        })
      );

      mainState.tables.features = [];
      response.data.data.tables.featureList.map((feature, index) =>
        mainState.tables.features.push({
          id: feature.id,
          name: feature.name,
          fullName: feature.fullName,
        })
      );

      mainState.tables.selfEnrollmentSemester = {
        id: response.data.data.tables.selfEnrollmentSemester.id,
        name: response.data.data.tables.selfEnrollmentSemester.name,
        startDate: response.data.data.tables.selfEnrollmentSemester.startDate,
        endDate: response.data.data.tables.selfEnrollmentSemester.endDate,
      };

      this.setState(mainState);
    });
  }

  render() {
    let privateRoutes = [];

    routes.map((prop, key) => {
      if (prop.children && prop.children.length > 0) {
        for (let index = 0; index < prop.children.length; index++) {
          const child = prop.children[index];
          privateRoutes.push(
            <PrivateRoute
              key={index}
              path={child.layout + child.path}
              component={child.component}
              tables={this.state.tables}
            />
          );
        }
      } else {
        privateRoutes.push(
          <PrivateRoute
            key={key}
            path={prop.layout + prop.path}
            component={prop.component}
            tables={this.state.tables}
          />
        );
      }
    });

    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={routes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />

        <div className="main-panel" ref={this.mainPanel}>
          {this.state.finished === true && (
            <Fragment>
              <DemoNavbar {...this.props} tables={this.state.tables} />

              <Switch>{privateRoutes}</Switch>

              {/* <Footer fluid /> */}
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default AdminLayout;
