/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import "assets/css/Login.css";
// import { authenticationService } from "services/Authentication.service.js";
import ForgotPassword from "../components/User/User.forgotpassword";
import confirm from "reactstrap-confirm";
import swal from "sweetalert";

class LoginPage extends Component {
  constructor(props) {
    super(props);

    // if (authenticationService.currentStudentValue) {
    //   this.props.history.push("/");
    // }

    this.state = {
      i20: "",
      pin: "",
      submitted: false,
      loading: false,
      error: "",
      isForgotPasswordModalOpen: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value, error: "" });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { i20, pin } = this.state;

    if (!(i20 && pin)) {
      return;
    }

    this.setState({ loading: true });

    // authenticationService.login(i20, pin).then(
    //   (student) => {
    //     const { from } = this.props.location.state || {
    //       from: { pathname: "/" },
    //     };

    //     this.props.history.push(from);
    //   },
    //   (error) => {
    //     let message = "Username or password is invalid";
    //     this.setState({ error: message, loading: false });

    //     document.getElementById("inputI20").focus();

    //     swal("Error", message, "error");
    //   }
    // );
  }

  validateForm() {
    return this.state.i20.length > 0 && this.state.pin.length > 0;
  }

  toggleisForgotPasswordModalOpen = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.isForgotPasswordModalOpen =
        !mainState.isForgotPasswordModalOpen;

      this.setState(mainState, resolve(true));
    });
  };

  render() {
    const { i20, pin, loading, error } = this.state;

    return (
      <div className="centerLogin text-center">
        <div className="card">
          <form className="form-signin" onSubmit={this.handleSubmit}>
            <img
              className="mb-4"
              src="https://storage.lumoslms.com/img/lumos_logo_circle.png"
              alt=""
              width="130"
            />
            <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>

            <input
              type="text"
              id="inputI20"
              className="form-control"
              placeholder="I-20"
              value={i20}
              onChange={this.handleChange}
              name="i20"
              required
            />

            <input
              type="pin"
              id="inputPin"
              className="form-control"
              placeholder="PIN"
              value={pin}
              onChange={this.handleChange}
              name="pin"
              required
            />
            <button
              className="btn btn-lg btn-primary btn-block"
              type="submit"
              disabled={loading || !this.validateForm()}
            >
              Sign in
            </button>

            <a onClick={() => this.toggleisForgotPasswordModalOpen()} href="#">
              Forgot your PIN?
            </a>

            <p className="mt-5 mb-3 text-muted">
              &copy; {new Date().getFullYear()} Lumos Language School
            </p>
            {/* {error && <div className={"loginAlert"}>{error}</div>} */}
          </form>
        </div>
        <ForgotPassword
          isOpen={this.state.isForgotPasswordModalOpen}
          toggle={this.toggleisForgotPasswordModalOpen.bind(this)}
          i20={this.state.i20}
        />
      </div>
    );
  }
}

export default LoginPage;
