import { AuthenticationContext, AdalConfig } from "react-adal";

export const adalConfig: AdalConfig = {
  tenant: "9414f868-ae51-49ff-8831-5d9631d1b057",
  clientId: "607c59d2-5c5b-4bb5-9c4b-5f7a753f1103",
  redirectUri: "https://student.lumos.edu/signin-oidc",
  endpoints: {
    api: "api://607c59d2-5c5b-4bb5-9c4b-5f7a753f1103/StudentPortalScope",
  },
  cacheLocation: "localStorage",
  postLogoutRedirectUri: "https://student.lumos.edu",
};

export const authContext = new AuthenticationContext(adalConfig);
export const getToken = () => authContext.getCachedToken(adalConfig.clientId);
export const getCachedUser = () => authContext.getCachedUser().userName;

// tenant: "4593b27e-4594-417c-8ffb-1d7e2ad6c1f2",
// clientId: "1bf81151-bd20-4342-9132-bd511b1a7858",
// redirectUri: "http://localhost:3000/signin-oidc",
// endpoints: {
//   api: "api://1bf81151-bd20-4342-9132-bd511b1a7858/Test_scope",
// },
// cacheLocation: "localStorage",
// postLogoutRedirectUri: "http://localhost:3000",
