/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Button,
  Collapse,
} from "reactstrap";
import axios from "axios";
import myData from "../Countries.json";
import {
  FormatFullDateList,
  FormatMonthYearDateList,
  editMode,
} from "helpers/Utils.js";
import { SavePdf } from "components/Report/GeneratePdf.js";
import * as dateFns from "date-fns";
import { Doughnut, Bar } from "react-chartjs-2";
import { colorArray } from "colors.js";
import ModalMessage from "components/Message/Message.Component";
import CustomCalendar from "../components/Calendar/Calendar.component";
// import { authenticationService } from "services/Authentication.service.js";

const campusColor = ["#6ad0b3", "#fd7c6e", "#fbc657"];

class Attendance extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();
  }

  getMainState = () => {
    return {
      AttendanceList: [],
    };
  };

  render() {
    let semesters = [];

    let currentSemester = this.props.tables.semesters.find(
      (x) => x.current === true
    );

    let startDate = dateFns.startOfMonth(
      dateFns.parseISO(currentSemester.startDate)
    );

    let endDate = dateFns.endOfMonth(dateFns.parseISO(currentSemester.endDate));

    let totalMonths = this.monthDiff(startDate, endDate);

    semesters = totalMonths.map((month, index) => {
      return (
        <Col md={6} key={index}>
          {" "}
          <CustomCalendar
            attendance={this.filterAttendance(month)}
            currentMonth={month}
            currentSemester={currentSemester}
            tables={this.props.tables}
          />
        </Col>
      );
    });

    return (
      <>
        <div className="content">
          <Row>{semesters}</Row>
        </div>
      </>
    );
  }

  async componentDidMount() {
    this._refreshAttendance();
  }

  _refreshAttendance = async () => {
    await axios.get("students/GetAttendance").then((response) => {
      let attendanceList = [];

      response.data.data.map((attendance, index) =>
        attendanceList.push({
          id: attendance.id,
          className: attendance.classe.code,
          campusName: attendance.classe.campus.name,
          date: FormatFullDateList(attendance.date),
          status: attendance.attendanceStudentViewModels[0].status,
          classBlock: attendance.classe.block,
          createdDate: FormatFullDateList(attendance.createdDate),
          attendanceStudentId: attendance.attendanceStudentViewModels[0].id,
          dateISO: attendance.date,
          Start: attendance.classe.subjectTime.start,
          subjectTimeEnd: attendance.classe.subjectTime.end,
        })
      );

      this.setState({ AttendanceList: attendanceList });
    });
  };

  filterAttendance(date) {
    return this.state.AttendanceList.filter(
      (x) => x.date.substring(0, 2) === dateFns.format(date, "MM")
    );
  }

  monthDiff(startDate, endDate) {
    var result = [];

    while (dateFns.isBefore(startDate, endDate)) {
      result.push(startDate);
      startDate = dateFns.addMonths(startDate, 1);
    }

    return result;
  }
}

export default Attendance;
