import React from "react";
import ReactDOM from "react-dom";
import { runWithAdal } from "react-adal";
import { authContext } from "./services/adalConfig";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.1.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "assets/css/App.css";
import "assets/css/flags.css";
import "react-credit-cards-2/dist/es/styles-compiled.css";

import { App } from "App/App.jsx";

const DO_NOT_LOGIN = false;

runWithAdal(
  authContext,
  () => {
    ReactDOM.render(<App />, document.getElementById("root"));
  },
  DO_NOT_LOGIN
);
