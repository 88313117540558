// import { authenticationService } from "services/Authentication.service.js";
import { getToken } from "../services/adalConfig";

export function authHeader() {
  // const user = authenticationService.currentStudentValue;
  const token = getToken();

  let obj = { "Content-Type": "application/json" };

  obj.Authorization = "Bearer " + token;

  return obj;
}
