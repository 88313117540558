/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Button,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import myData from "../Countries.json";
import {
  FormatFullDateList,
  FormatMonthYearDateList,
  editMode,
} from "helpers/Utils.js";
import { SavePdf } from "components/Report/GeneratePdf.js";
import * as dateFns from "date-fns";
import { Doughnut, Bar } from "react-chartjs-2";
import { colorArray } from "colors.js";
import ModalMessage from "components/Message/Message.Component";
import CustomCalendar from "../components/Calendar/Calendar.component";

const campusColor = ["#6ad0b3", "#fd7c6e", "#fbc657"];

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();
  }

  getMainState = () => {
    return {};
  };

  render() {
    return (
      <>
        <div className="content">
          <Row form>
            <img
              className="mb-4"
              src="https://hubstorage.lumos.edu/img/calendar.jpg"
            />
          </Row>
        </div>
      </>
    );
  }
}

export default Calendar;
